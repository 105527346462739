import React from "react";

const WorkExperience = () => {
  return (
    <article>
      <h3>WORK EXPERIENCE</h3>
      <div className="titleWrapper">
        <h4 className="sectionItemTitle">
          Senior Manager, Software Development
        </h4>
        <span className="sectionItemSubtitle">Bentley Systems, Inc.</span>
      </div>
      <span className="emphasizedSubtitle">05/2011 - Present</span>
      <div className="bulletList">
        <span className="emphasizedSubtitle">Achievements/Tasks</span>
        <ul>
          <li>
            Directed team of 1 manager and 8 indirect reports responsible for
            the creation, management, and maintenance of high-performance, higly
            scalable, multi-region backend services running on Kubernetes in the
            Microsoft Azure cloud. These services were critical to the operation
            of Bentley's iTwin Platform.
          </li>
          <li>
            Led team of 4 direct reports responsible for Bentley's iTwin Viewer,
            the React component at the core of the iTwin Platform's web and
            desktop offerings.
          </li>
          <li>
            Led team of 2 direct reports responsible for Bentley's iTwin Mobile
            SDK, which provides native iOS and Android packages enabling apps to
            access iTwin Platform visualization services.
          </li>
          <li>
            Assembled a team of 7 direct reports and created Ad-Hoc Design
            Review, Bentley's first cloud-native app. It was built and released
            in only three months at an organization where product launch
            timelines were typically measured in years. This was a hands-on role
            involving extensive work in React (including hooks) and Node.js
          </li>
          <li>
            Built OpenRoads Navigator -- an app designed for Civil engineers to
            use on their iPad or Android tablets -- using an in-house
            cross-platform SDK in JavaScript and C++.
          </li>
        </ul>
      </div>
    </article>
  );
};

export default WorkExperience;
